<template>
  <div>
    <div class="title">KYC详情提交</div>
    <div class="tishi" v-if="msg">{{ msg }}</div>
    <van-field
			:value="itext"
			is-link
			readonly
			label="性别"
			placeholder="请选择性别"
			@click="atmEnable==0?ishow = true:''"
		/>
		<van-popup v-model="ishow" round position="bottom">
			<van-picker
				:columns="ilist"
				@cancel="ishow = false"
				@confirm="iOnConfirm"
				show-toolbar
			/>
		</van-popup>
    <van-field
			:value="init.birthday"
			is-link
			readonly
			label="生日"
			placeholder="请选择生日"
			@click="atmEnable==0?bshow = true:''"
		/>
    <van-popup v-model="bshow" round position="bottom">
			<van-datetime-picker
				type="date"
				@cancel="bshow = false"
				@confirm="bOnConfirm"
				:min-date="minDate"
				:max-date="maxDate"
			/>
		</van-popup>
    <van-field
      v-model="init.address"
      label="地址"
      placeholder="请输入地址"
      :readonly="atmEnable==1"
    />
    <van-field
      :value="text"
      is-link
      readonly
      label="现国籍"
      placeholder="请选择现国籍"
      @click="atmEnable==0?isShow = true:''"
    />
    <van-popup v-model="isShow" round position="bottom">
      <van-picker
        :columns="list"
        value-key="name"
        @cancel="isShow = false"
        @confirm="onConfirm"
        show-toolbar
      />
    </van-popup>
    <van-field
      :value="text2"
      is-link
      readonly
      label="出生地"
      placeholder="请选择出生地"
      @click="atmEnable==0?isShow2 = true:''"
    />
    <van-popup v-model="isShow2" round position="bottom">
      <van-picker
        :columns="list2"
        value-key="name"
        @cancel="isShow2 = false"
        @confirm="onConfirm2"
        show-toolbar
      />
    </van-popup>
    <van-field
      :value="text3"
      is-link
      readonly
      label="居住地"
      placeholder="请选择居住地"
      @click="atmEnable==0?isShow3 = true:''"
    />
    <van-popup v-model="isShow3" round position="bottom">
      <van-picker
        :columns="list3"
        value-key="name"
        @cancel="isShow3 = false"
        @confirm="onConfirm3"
        show-toolbar
      />
    </van-popup>
    <!-- <van-field
      v-model="init.nationality_code"
      label="现国籍"
      disabled
    /> -->
    <!-- <van-field
      v-model="init.birth_country_code"
      label="出生地"
    />
    <van-field
      v-model="init.residence_country"
      label="居住地"
    /> -->
    <van-field v-model="img1" label="住址证明">
      <template #input>
        <van-image 
          width="100" 
          height="100" 
          :src="image_preview1"
          style="vertical-align: middle;" 
          @click="atmEnable==0?qrcodeUpload(1):''"/>
      </template>
    </van-field>
    <!-- <van-field v-model="img2" >
      <template #input>
        <van-image 
          width="100" 
          height="100" 
          :src="image_preview2"
          style="vertical-align: middle;" 
          @click="qrcodeUpload(2)"/>
      </template>
    </van-field> -->
    <div class="common_block" >
      <van-button type="warning" v-if="kycAddEnable==1" block @click="postInit">{{translate('submit_amount_options')}}</van-button>
      <van-button type="warning" v-if="atmEnable==1" block  @click="atmpin()">设置ATM密码</van-button>
      <van-button type="warning" v-if="active==1" block @click="activekq()">激活</van-button>
    </div>
  </div>
</template>
<script>
  export default {
		name: 'personal_step6',
		data() {
			return {
        
        msg:'',
        next:true,
        atmtf:true,
        bshow:false,
        minDate: new Date(1930, 1, 1),
				maxDate: new Date(2040, 10, 1),
        ishow:false,
        itext:'',
        ilist:[{text:'男',value:'Male'},{text:'女',value:'Female'}],
        step:0,
        active:0,
        kycAddEnable:0,
        atmEnable:0,
        img1:'',
        img2:'',
        image_preview1: this.$axios.defaults.resourceURL + "images/img_upload.png",
        image_preview2: this.$axios.defaults.resourceURL + "images/img_upload.png",
        imgtype:1,
				init:{
					address:"",
					birthday:"",
					gender:"",
					nationality_code:156,
          birth_country_code:156,
					residence_country:156,
					file_url_list:[]
				},
        isShow:false,
        isShow2:false,
        isShow3:false,
        list:[],
        list2:[],
        list3:[],
        text:'中国 内地',
        text2:'中国 内地',
        text3:'中国 内地',
        
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			}
      this.getProtocol()
      this.getAddInit()
      this.getCclist()
      // window.set_pic = this.set_pic
      window.set_qrcode_pic = this.set_qrcode_pic
		},
		methods: {
      activekq(){
        // 开启
        this.$axios({
          method:'post',
          url:'speedpay_card/active',
          data:{
						token:this.$route.query.token,
					}
				}).then ((res) => {
					if (res.success) {
            this.$router.replace('/speedpay/step7?token='+this.$route.query.token)
					}else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
      },
      getKycNewest(){
        this.$axios({
          method: 'post',
          url: 'speedpay_kyc/newest', 
          data: {
            token: this.$route.query.token,
          },
        }).then ((res) => {
          if (res.success) {
            
            this.init.nationality_code=res.data.nationality_code||156
            this.init.birth_country_code=res.data.birth_country_code||156
            this.init.residence_country=res.data.residence_country||156
            // this.init.file_url_list=res.data.file_url_list||[]
            let myimgs=JSON.parse(res.data.file_url_list);
            if(myimgs){
              this.image_preview1=myimgs[0]
              this.img1=myimgs[0]
            }
            this.text=this.list.find(data => data.number_code == this.init.nationality_code).name;
            this.text2=this.list2.find(data => data.number_code == this.init.birth_country_code).name;
            this.text3=this.list3.find(data => data.number_code == this.init.residence_country).name;
          }else{
            this.$dialog.alert({
              message: res.error_text
            })
          }
        })
      },
      bOnConfirm(e){
				this.init.birthday=this.formatDate(e)
				this.bshow=false
			},
      iOnConfirm(e){
				this.itext=e.text
				this.init.gender=e.value
				this.ishow=false
			},
      qrcodeUpload(type) {
        this.imgtype=type;
				if (window.JsObject != null) {
					// window.JsObject.normalImageUpload();
          window.JsObject.usdtQRImageUpload()
				}
				
				if (window.webkit != null) {
					window.webkit.messageHandlers.jsToOcWithPrams.postMessage({"camera": "normal"});
				}
			},
      set_qrcode_pic(path) {
        var type=this.imgtype;
        if(type==1){
          this.img1=this.$axios.defaults.imgURL + path
        }else{
          this.img2=this.$axios.defaults.imgURL + path
        }
				this.$axios({
					method: 'post',
					url: 'rss_deposit/resize', 
					data: {
						token: this.$route.query.token,
						image: path
					},
				}).then ((res) => {
					if (res.success) {
            if(type==1){
              this.image_preview1=res.data.preview
            }else{
              this.image_preview2=res.data.preview
            }
					} else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
        
			},
      onConfirm(e){
        this.isShow=false;
        this.text=e.name;
        this.init.nationality_code=e.number_code;
      },
      onConfirm2(e){
        this.isShow2=false;
        this.text2=e.name;
        this.init.birth_country_code=e.number_code;
      },
      onConfirm3(e){
        this.isShow3=false;
        this.text3=e.name;
        this.init.residence_country=e.number_code;
      },
      getProtocol(){
        this.$axios({
					method: 'post',
					url: 'speedpay_user/index', 
					data: {
            token: this.$route.query.token,
          }
				}).then ((res) => {
					if (res.success) {
            this.step=res.data.step;
            this.kycAddEnable=res.data.kycAddEnable;
            this.atmEnable=res.data.atmEnable;
            this.active=res.data.active;
            this.msg=res.data.msg;

					}else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
      },
      getAddInit(){
				this.show = true;
				this.$axios({
					method: 'post',
					url: 'speedpay_kyc/addInit',
					data: {
						token:this.$route.query.token
					}
				}).then ((res) => {
					if (res.success) {
						this.init = Object.assign({}, this.init, res.data);
            if(res.data.gender=="Male"){
              this.itext="男"
            }else{
              this.itext="女"
            }
					}else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			},
			getCclist(){
				this.$axios({
					method: 'post',
					url: 'speedpay_common/cclist',
					data: {
						token:this.$route.query.token
					}
				}).then ((res) => {
					if (res.success) {
						this.list =res.data;
            this.list2 =res.data;
            this.list3 =res.data;
            this.getKycNewest()
					}else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			},
      postInit(){
        if(this.init.gender==""){
          this.$toast("请选择性别");
          return;
        }
        if(this.init.birthday==""){
          this.$toast("请选择生日");
          return;
        }
        if(this.init.address==""){
          this.$toast("请输入居住地址");
          return;
        }
        if(this.init.img1==""){
          this.$toast("请上传图片");
          return;
        }
        
        if(!this.next){
          return;
        }
        this.next=false;
        this.init.file_url_list.push(this.img1)
        // this.init.file_url_list.push(this.img2)
				this.init.file_url_list=JSON.stringify(this.init.file_url_list)
				this.$axios({
					method: 'post',
					url: 'speedpay_kyc/add',
					data: {
						token:this.$route.query.token,
						...this.init
					}
				}).then ((res) => {
          this.next=true;
					if (res.success) {
						this.getProtocol()
            this.$dialog.alert({
							message: '提交成功'
						})
					}else {
						this.$dialog.alert({
							message: res.error_text
						})
            setTimeout(()=>{
							this.$router.replace('/speedpay/index?token='+this.$route.query.token)
						},2000)
					}
				})
			},
			atmpin(){
        if(!this.atmtf){
          return;
        }
        this.atmtf=false;
				this.$axios({
					method: 'post',
					url: 'speedpay_card/atmpin',
					data: {
						token:this.$route.query.token
					}
				}).then ((res) => {
					if (res.success) {
						var url=decodeURI(res.data.redirectUrl)
						window.location.href=url
					}else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
          this.atmtf=true;
				})
			},
      formatDate(date){
				return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
			},
		},
	}
</script>
<style scoped>
  .title{
    font-weight: bold;
    text-align: center;
    padding: 15px 0;
    text-align: center;
    background: #fff;
    margin-bottom: 10px;
  }
  .tishi{
    padding: 20px;
    background: #fff;
    margin-bottom: 15px;
  }
</style>